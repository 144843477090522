import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
export default function HomeWhatOurCustomerSays() {
    return (
        <>

            <section className="eq-feedback-section ptb-120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-9">
                            <div className="section-title ps-sm-3 text-center text-lg-start">
                                <h2 className="mb-2 text-eq-primary">Excelência em Atendimento</h2>
                                <p className="mb-3 fw-bold text-black">O Que Nossos Clientes Dizem?</p>
                                <p className="mb-0">Está em nosso DNA dar o melhor atendimento aos nossos clientes, gerando a melhor experiência de compra de veículos do mercado.</p>
                            </div>
                        </div>
                    </div>
                    <div className="py-5 px-sm-3">
                        <Swiper
                            speed={3500}
                            loop={true}
                            slidesPerView={3}
                            spaceBetween={24}
                            autoplay={{
                                delay: 3500,
                                disableOnInteraction: false
                            }}                         
                            pagination={{
                                el: ".eq-feedback-pagination",
                                type: "bullets",
                                clickable: true
                            }}
                            breakpoints={{
                                [0]: { slidesPerView: 1 },
                                [768]: { slidesPerView: 2 },
                                [1200]: { slidesPerView: 3 }
                            }}
                            modules={[Pagination, Autoplay]}
                            >
                            <SwiperSlide className="swiper-slide eq-feedback-single position-relative bg-white rounded overflow-hidden">
                                <span className="star-badge text-white position-absolute end-0 top-0 fs-sm fw-bold"><i className="fa-solid fa-star"></i>5.0</span>
                                <span className="quote-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                                    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.57941 7.84822H4.02222C3.71778 7.84822 3.45896 7.7417 3.24584 7.52858C3.03284 7.31546 2.9262 7.05672 2.9262 6.7522V6.38706C2.9262 5.58028 3.21165 4.89158 3.78251 4.32072C4.35334 3.75006 5.04216 3.4646 5.84885 3.4646H6.57941C6.77726 3.4646 6.94843 3.39227 7.0931 3.24773C7.23768 3.1031 7.30998 2.93193 7.30998 2.73404V1.27268C7.30998 1.07483 7.23764 0.903495 7.0931 0.75887C6.94847 0.614445 6.7773 0.541992 6.57941 0.541992H5.84885C5.05727 0.541992 4.30208 0.696254 3.58279 1.00434C2.86358 1.31262 2.2415 1.72934 1.7163 2.25454C1.19114 2.7795 0.774534 3.40163 0.466291 4.12092C0.158048 4.84012 0.00390625 5.59552 0.00390625 6.38702V14.4236C0.00390625 15.0328 0.216906 15.55 0.643104 15.9764C1.06934 16.4025 1.58699 16.6155 2.1958 16.6155H6.57969C7.18846 16.6155 7.70595 16.4025 8.13223 15.9764C8.55834 15.55 8.77146 15.0328 8.77146 14.4236V10.0401C8.77146 9.43106 8.55834 8.91382 8.13195 8.48742C7.70587 8.06134 7.18818 7.84822 6.57941 7.84822Z" fill="#F6F6F6" />
                                        <path d="M18.3607 8.48742C17.9346 8.06134 17.4171 7.84822 16.8081 7.84822H14.251C13.9467 7.84822 13.6876 7.7417 13.4749 7.52858C13.2617 7.31546 13.1552 7.05672 13.1552 6.7522V6.38706C13.1552 5.58028 13.4407 4.89158 14.0113 4.32072C14.5819 3.75006 15.2707 3.4646 16.0778 3.4646H16.8082C17.0061 3.4646 17.1773 3.39227 17.3219 3.24773C17.4664 3.1031 17.5389 2.93193 17.5389 2.73404V1.27268C17.5389 1.07483 17.4664 0.903495 17.3219 0.75887C17.1774 0.614445 17.0061 0.541992 16.8082 0.541992H16.0778C15.2857 0.541992 14.5307 0.696254 13.8112 1.00434C13.0921 1.31262 12.4703 1.72934 11.9451 2.25454C11.4199 2.7795 11.003 3.40163 10.6949 4.12092C10.3868 4.84012 10.2324 5.59552 10.2324 6.38702V14.4236C10.2324 15.0328 10.4456 15.55 10.8717 15.9764C11.2978 16.4025 11.8153 16.6155 12.4242 16.6155H16.8079C17.4168 16.6155 17.9343 16.4025 18.3604 15.9764C18.7868 15.55 18.9995 15.0328 18.9995 14.4236V10.0401C18.9996 9.43102 18.7868 8.91382 18.3607 8.48742Z" fill="#F6F6F6" />
                                    </svg>
                                </span>
                                <p className="mt-4 mb-3 fs-md">Muito satisfeita com a minha compra, desde a abordagem, até o pós vendas. Facilitaram bastante as condições, e as negociações. Um elogio especial ao vendedor, sempre muito solicito e atencioso. Muito sucesso.</p>
                                <p className="mb-5 clients_name position-relative fw-bold text-black">Ariane Quirino</p>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide eq-feedback-single position-relative bg-white rounded overflow-hidden">
                                <span className="star-badge text-white position-absolute end-0 top-0 fs-sm fw-bold"><i className="fa-solid fa-star"></i>5.0</span>
                                <span className="quote-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                                    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.57941 7.84822H4.02222C3.71778 7.84822 3.45896 7.7417 3.24584 7.52858C3.03284 7.31546 2.9262 7.05672 2.9262 6.7522V6.38706C2.9262 5.58028 3.21165 4.89158 3.78251 4.32072C4.35334 3.75006 5.04216 3.4646 5.84885 3.4646H6.57941C6.77726 3.4646 6.94843 3.39227 7.0931 3.24773C7.23768 3.1031 7.30998 2.93193 7.30998 2.73404V1.27268C7.30998 1.07483 7.23764 0.903495 7.0931 0.75887C6.94847 0.614445 6.7773 0.541992 6.57941 0.541992H5.84885C5.05727 0.541992 4.30208 0.696254 3.58279 1.00434C2.86358 1.31262 2.2415 1.72934 1.7163 2.25454C1.19114 2.7795 0.774534 3.40163 0.466291 4.12092C0.158048 4.84012 0.00390625 5.59552 0.00390625 6.38702V14.4236C0.00390625 15.0328 0.216906 15.55 0.643104 15.9764C1.06934 16.4025 1.58699 16.6155 2.1958 16.6155H6.57969C7.18846 16.6155 7.70595 16.4025 8.13223 15.9764C8.55834 15.55 8.77146 15.0328 8.77146 14.4236V10.0401C8.77146 9.43106 8.55834 8.91382 8.13195 8.48742C7.70587 8.06134 7.18818 7.84822 6.57941 7.84822Z" fill="#F6F6F6" />
                                        <path d="M18.3607 8.48742C17.9346 8.06134 17.4171 7.84822 16.8081 7.84822H14.251C13.9467 7.84822 13.6876 7.7417 13.4749 7.52858C13.2617 7.31546 13.1552 7.05672 13.1552 6.7522V6.38706C13.1552 5.58028 13.4407 4.89158 14.0113 4.32072C14.5819 3.75006 15.2707 3.4646 16.0778 3.4646H16.8082C17.0061 3.4646 17.1773 3.39227 17.3219 3.24773C17.4664 3.1031 17.5389 2.93193 17.5389 2.73404V1.27268C17.5389 1.07483 17.4664 0.903495 17.3219 0.75887C17.1774 0.614445 17.0061 0.541992 16.8082 0.541992H16.0778C15.2857 0.541992 14.5307 0.696254 13.8112 1.00434C13.0921 1.31262 12.4703 1.72934 11.9451 2.25454C11.4199 2.7795 11.003 3.40163 10.6949 4.12092C10.3868 4.84012 10.2324 5.59552 10.2324 6.38702V14.4236C10.2324 15.0328 10.4456 15.55 10.8717 15.9764C11.2978 16.4025 11.8153 16.6155 12.4242 16.6155H16.8079C17.4168 16.6155 17.9343 16.4025 18.3604 15.9764C18.7868 15.55 18.9995 15.0328 18.9995 14.4236V10.0401C18.9996 9.43102 18.7868 8.91382 18.3607 8.48742Z" fill="#F6F6F6" />
                                    </svg>
                                </span>
                                <p className="mt-4 mb-3 fs-md">Nota 10 para o atendimento da galera da Loja. melhor atendimento da região. Desde o primeiro contato até o pós venda. com certeza voltarei a comprar com eles. A garantia de 1 ano nos carros é um diferencial. parabéns!</p>
                                <p className="mb-5 clients_name position-relative fw-bold text-black">Pedro Henrique Silva de Carvalho</p>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide eq-feedback-single position-relative bg-white rounded overflow-hidden">
                                <span className="star-badge text-white position-absolute end-0 top-0 fs-sm fw-bold"><i className="fa-solid fa-star"></i>5.0</span>
                                <span className="quote-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                                    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.57941 7.84822H4.02222C3.71778 7.84822 3.45896 7.7417 3.24584 7.52858C3.03284 7.31546 2.9262 7.05672 2.9262 6.7522V6.38706C2.9262 5.58028 3.21165 4.89158 3.78251 4.32072C4.35334 3.75006 5.04216 3.4646 5.84885 3.4646H6.57941C6.77726 3.4646 6.94843 3.39227 7.0931 3.24773C7.23768 3.1031 7.30998 2.93193 7.30998 2.73404V1.27268C7.30998 1.07483 7.23764 0.903495 7.0931 0.75887C6.94847 0.614445 6.7773 0.541992 6.57941 0.541992H5.84885C5.05727 0.541992 4.30208 0.696254 3.58279 1.00434C2.86358 1.31262 2.2415 1.72934 1.7163 2.25454C1.19114 2.7795 0.774534 3.40163 0.466291 4.12092C0.158048 4.84012 0.00390625 5.59552 0.00390625 6.38702V14.4236C0.00390625 15.0328 0.216906 15.55 0.643104 15.9764C1.06934 16.4025 1.58699 16.6155 2.1958 16.6155H6.57969C7.18846 16.6155 7.70595 16.4025 8.13223 15.9764C8.55834 15.55 8.77146 15.0328 8.77146 14.4236V10.0401C8.77146 9.43106 8.55834 8.91382 8.13195 8.48742C7.70587 8.06134 7.18818 7.84822 6.57941 7.84822Z" fill="#F6F6F6" />
                                        <path d="M18.3607 8.48742C17.9346 8.06134 17.4171 7.84822 16.8081 7.84822H14.251C13.9467 7.84822 13.6876 7.7417 13.4749 7.52858C13.2617 7.31546 13.1552 7.05672 13.1552 6.7522V6.38706C13.1552 5.58028 13.4407 4.89158 14.0113 4.32072C14.5819 3.75006 15.2707 3.4646 16.0778 3.4646H16.8082C17.0061 3.4646 17.1773 3.39227 17.3219 3.24773C17.4664 3.1031 17.5389 2.93193 17.5389 2.73404V1.27268C17.5389 1.07483 17.4664 0.903495 17.3219 0.75887C17.1774 0.614445 17.0061 0.541992 16.8082 0.541992H16.0778C15.2857 0.541992 14.5307 0.696254 13.8112 1.00434C13.0921 1.31262 12.4703 1.72934 11.9451 2.25454C11.4199 2.7795 11.003 3.40163 10.6949 4.12092C10.3868 4.84012 10.2324 5.59552 10.2324 6.38702V14.4236C10.2324 15.0328 10.4456 15.55 10.8717 15.9764C11.2978 16.4025 11.8153 16.6155 12.4242 16.6155H16.8079C17.4168 16.6155 17.9343 16.4025 18.3604 15.9764C18.7868 15.55 18.9995 15.0328 18.9995 14.4236V10.0401C18.9996 9.43102 18.7868 8.91382 18.3607 8.48742Z" fill="#F6F6F6" />
                                    </svg>
                                </span>
                                <p className="mt-4 mb-3 fs-md">Atendimento excelente desde o primeiro contato! Um ótimo local para adquirir veículos. Saí de Praia Grande para olhar o veículo e não deu outra, negócio fechado! Resultado de um ótimo atendimento somado a produto de qualidade. Deixo meu obrigado ao vendedor, que prestou um verdadeiro serviço de consultoria. Parabéns a toda a equipe e sucesso!</p>
                                <p className="mb-5 clients_name position-relative fw-bold text-black">Vinicius Silva</p>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

        </>
    );
}